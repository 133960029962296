import React, { useEffect, useState } from 'react';
import { GImage } from '../../g-image';
import { GStarRating } from '../../g-star-rating';
import Favorite from '../../favorite-button';
import { AppLink } from '@/Components/link/link';
import { BasicTrip, Promotion, TripPrice } from '@/Lib/types/trip';
import { round } from '@/Lib/helpers/round-number';
import { useQuery } from '@apollo/client';
import { GET_TRIP_PRICE } from '@/Lib/graphql/queries/trip.query';
import TourIconSVG from '@/Components/search-panel/search-panel-tabs/tour-icon-svg';
import { Price } from './price';
import { useAtom, useAtomValue } from 'jotai';
import {
  compareProductAtom,
  pageDetail,
  promoUpdateTriggerAtom,
  promotionsAtom,
} from '@/State/global/global.store';
import { currencySymbols } from '../../../data/currency';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { useDynamicPrice } from '@/Lib/hooks/useDynamicPrice';
import { usePrice } from '@/Lib/hooks/usePrice';
import { useCurrency } from '@/Lib/hooks/useCurrency';
import { useTimerPromo } from '@/Lib/hooks/useTimerPromo';
import { useExperimentalFeature } from '@/Lib/hooks/useExperimentalFeature';
import { AB_TEST_FEATURE } from 'data/flags';
import { CSSTransition } from 'react-transition-group';
import { useFlags } from 'flagsmith/react';
import { ProductCardCompare } from './compare';
import { useCompare } from '@/Lib/hooks/useCompare';
import { useDevice } from '@/Lib/hooks/useDevice';

interface ProductCardProps {
  trip: BasicTrip;
  clickProductCard?: any;
}

function ProductCard({
  trip,
  clickProductCard,
}: ProductCardProps): JSX.Element {
  const pageDetailInfo = useAtomValue(pageDetail);
  const isCompareEnabled = useExperimentalFeature(
    AB_TEST_FEATURE.Product_compare,
    'PCF'
  );

  const { toggleCompare } = useCompare();

  const { isMD, isLG } = useDevice();

  const { data: tripData } = useQuery<{ trip: TripPrice }>(GET_TRIP_PRICE, {
    variables: {
      id: trip.id,
    },
  });

  const { currency } = useCurrency();
  const [discount, setDiscount] = useState<number>(0);
  const [reducedDeposit, setReducedDeposit] = useState<number>(0);
  const [promos, setPromos] = useState<Promotion[]>([]);
  const divisionInternalName = getInternalName(trip.division);
  const dynamicPrice = useDynamicPrice(divisionInternalName);
  const [compareProductList, setCompareProductList] =
    useAtom(compareProductAtom);

  const [checkedCompare, setCheckedCompare] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isCompareActive, setIsCompareActive] = useState(true);

  const [isSpecificCompare, setIsSpecificCompare] = useState(false);

  const shouldShowOverflow =
    isCompareEnabled && isCompareActive && isHovered && isSpecificCompare;

  const { price, discountedPrice } = usePrice({
    basePrice: trip.prices,
    dynamicPrice,
    discount,
  });

  const flags = useFlags(['UB_promotion_timer']);
  const promoFlagEnabled = flags?.UB_promotion_timer?.enabled;
  const shouldShowPromoTimer =
    promoFlagEnabled && flags?.UB_promotion_timer?.value === 'new_version';
  const timerPromo = useTimerPromo(promos);
  const { promotions: appliedPromotions, getComputedPromos } =
    useAtomValue(promotionsAtom);
  const promoUpdateTrigger = useAtomValue(promoUpdateTriggerAtom);

  const compare = (tripId: number) => {
    toggleCompare(
      Number(tripId),
      trip.destination,
      trip.reviews.rating,
      trip.bannerImg,
      trip.tripName,
      trip.division
    );
    setCheckedCompare(!checkedCompare);
  };

  useEffect(() => {
    const p = getComputedPromos
      ? getComputedPromos(
          trip.promotions,
          appliedPromotions as Promotion[],
          trip,
          trip.prices[currency],
          tripData?.trip.deposit[currency] || 0,
          currency
        )
      : { discount: 0, reducedDeposit: 0, promotions: [] };

    setDiscount(p.discount);
    setReducedDeposit(p.reducedDeposit);
    setPromos(p.promotions);
  }, [
    promoUpdateTrigger,
    appliedPromotions,
    currency,
    getComputedPromos,
    trip,
    tripData?.trip.deposit,
  ]);

  let hasAgeRange = trip.age.min || trip.age.max || null;

  useEffect(() => {
    const coms = compareProductList;
    if (coms && coms.findIndex((item) => item.tripId === trip.id) > -1) {
      setCheckedCompare(true);
    } else {
      setCheckedCompare(false);
    }
    if (coms && coms.length >= 3) {
      setIsCompareActive(false);
    } else {
      setIsCompareActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip.id, compareProductList]);

  useEffect(() => {
    setIsSpecificCompare(
      pageDetailInfo.name === 'division' &&
        pageDetailInfo.detail === 'working-holiday'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDetailInfo]);
  return (
    <article
      className="group relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="w-full lg:w-auto bg-light-600 rounded-md overflow-hidden border border-light-700 relative cursor-pointer"
        style={{ boxShadow: '2px 2px 6px 0 rgb(0 0 0 / 15%)' }}
      >
        {trip.tag ? (
          <div className="bg-gray-900 bg-opacity-60 py-1 px-3 font-bold rounded-full text-2xs leading-3.5 text-light-700 absolute top-3.5 left-3.5 z-20">
            {trip.tag}
          </div>
        ) : null}
        <AppLink
          href={trip.url}
          className="flex flex-col justify-between"
          onClick={clickProductCard}
        >
          <div>
            <div className="relative bg-light-700 h-48 overflow-hidden">
              {trip.bannerImg && (
                <GImage
                  path={trip.bannerImg}
                  transformation="trip-cards"
                  alt={trip.tripName}
                  height="100%"
                  width="100%"
                  hasLoadingBackground
                  useGallery={true}
                />
              )}

              <Favorite
                deposit={
                  tripData?.trip?.deposit[currency]
                    ? tripData?.trip?.deposit[currency] - reducedDeposit
                    : 0
                }
                price={discountedPrice}
                revenue={discountedPrice}
                type="productcard"
                trip={trip}
                originalPrice={price}
                originalDeposite={
                  tripData?.trip?.deposit[currency]
                    ? tripData?.trip?.deposit[currency]
                    : 0
                }
              />
              <div
                className={
                  'absolute inset-0 z-10 transition-colors duration-300 bg-dark-900 bg-opacity-0 group-hover:bg-opacity-20 '
                }
              ></div>
            </div>
            <div className="p-3.5 lg:p-5 min-h-10 box-border">
              <div className="flex flex-wrap text-dark-900 capitalize font-normal leading-5 text-2xs gap-x-4 whitespace-nowrap">
                <div className="flex items-center">
                  <i className="icon-list mr-1.5 text-sm"></i> {trip.division}
                </div>
                <div className="flex items-center">
                  <i className="icon-location mr-1.5 text-sm"></i>
                  {trip.destination}
                </div>
                {trip.division === 'Tour' && (
                  <div className="flex items-center">
                    <div className="mr-1.5">
                      <TourIconSVG isSmall />
                    </div>
                    {trip.partner?.name ?? ''}
                  </div>
                )}
              </div>
              <h3 className="text-lg font-semibold lg:text-xl lg:font-semibold lg:leading-6 my-2 text-dark-800 group-hover:underline">
                {trip.tripName}
              </h3>
              <div className="text-sm leading-3 font-semibold flex items-center flex-wrap text-dark-900 space-x-1.5">
                {!(
                  trip.reviews?.count < 10 && Number(trip.reviews?.rating) < 4
                ) ? (
                  <React.Fragment>
                    <GStarRating rating={Number(trip.reviews?.rating)} />
                    <div className="font-semibold text-sm leading-4">
                      {round(Number(trip.reviews?.rating), 1)}
                    </div>
                    <div className="text-xs font-normal">
                      ({trip.reviews?.count} reviews)
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
            <div className="text-2xs leading-3 font-semibold flex items-center flex-wrap text-dark-900 gap-1 px-4 lg:px-5 mb-2">
              {hasAgeRange !== null && (
                <div className="py-1 px-1.5 rounded-full font-bold bg-purple-700 text-purple-900">
                  {trip.age.min}
                  {trip.age.max ? '-' + trip.age.max : '+'} y/o
                </div>
              )}
              <div className="py-1 px-1.5 rounded-full font-bold bg-teal-500 text-teal-900">
                {trip.duration.min === trip.duration.max
                  ? `${trip.duration.min} ${trip.durationType}s`
                  : `${trip.duration.min}-${trip.duration.max} ${trip.durationType}s`}
              </div>

              {discount && !(timerPromo && shouldShowPromoTimer) ? (
                <div className="py-1 px-1.5 rounded-full font-bold bg-redSuperLight text-redCards">
                  {currencySymbols[currency] + discount} off
                </div>
              ) : null}
            </div>
            {!trip.isDegreeRequired && trip.division === 'Teach' ? (
              <div className="px-4 lg:px-5 flex items-center mt-2 ">
                <div className="h-5 px-1.5 text-2xs leading-3.5 font-semibold rounded-full text-dark-700 bg-blueSuperLight flex justify-center items-center">
                  No degree required
                </div>
              </div>
            ) : (
              <div className="h-5"></div>
            )}
          </div>
          <div
            className={
              'border-t border-light-700 flex jsutify-between' +
              (timerPromo && shouldShowPromoTimer
                ? ' p-5'
                : ' px-5 py-2.125rem')
            }
          >
            <Price
              discount={discount}
              price={price}
              discountedPrice={discountedPrice}
              promos={promos}
            />
          </div>
        </AppLink>
        {isSpecificCompare && isCompareEnabled && !isLG ? (
          <ProductCardCompare
            checkedCompare={checkedCompare}
            compare={(tripId: number) => compare(tripId)}
            isCompareActive={isCompareActive}
            tripId={trip.id.toString()}
          />
        ) : null}
      </div>
      <CSSTransition
        in={shouldShowOverflow}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div
          className="!bg-light-600 z-100 hidden lg:flex lg:flex-col absolute top[100%] border-l border-r border-light-700 w-full items-start rounded-b-md pb-4 -mt-1"
          style={{
            boxShadow: `2px 5px 6px 0 rgb(0 0 0 / 15%)`,
          }}
        >
          {isSpecificCompare && isCompareEnabled ? (
            <div className="w-full border-t border-light-800 border-opacity-50  mb-4"></div>
          ) : null}
          {isSpecificCompare && isCompareEnabled ? (
            <ProductCardCompare
              checkedCompare={checkedCompare}
              compare={compare}
              isCompareActive={isCompareActive}
              tripId={trip.id.toString()}
            />
          ) : null}
        </div>
      </CSSTransition>
    </article>
  );
}

function ProductCardSkeleton() {
  return (
    <article className="bg-light-600 rounded-md shadow-md overflow-hidden border border-light-700 relative animate-pulse max-w-xs">
      <div className="bg-light-900 h-50 overflow-hidden animate-pulse"></div>
      <div className="p-4 min-h-10 box-border lg:p-5">
        <div className="h-6 bg-light-900 my-1"></div>
        <div className="my-1 h-5 bg-light-900"></div>
        <div className="my-1 h-5 w-12 bg-light-900"></div>
      </div>
      <div className="border-t border-light-900 flex justify-between p-4 lg:px-5">
        <div className="my-1 h-5 w-12 bg-light-900"></div>
        <div className="my-1 h-5 w-12 bg-light-900"></div>
      </div>
    </article>
  );
}

export { ProductCard, ProductCardSkeleton };
