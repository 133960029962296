import { TSimpleUser } from '@/DataTypes/user';
import { SmartStorage } from '@/Lib/services/smart-storage/smart-storage';
import { StorageKeys } from '@/Lib/services/storage-keys';

const userInfoStore = SmartStorage.createWithLocalStorage().over(
  StorageKeys.UserInfo
);

export function setUserInfoFPDC(user: TSimpleUser): boolean {
  if (window) {
    const userData = getUserInfoFPDC();
    if (!userData) {
      userInfoStore.set(JSON.stringify(user));
    }
    return true;
  }
  return false;
}

export function getUserInfoFPDC(): TSimpleUser | null {
  if (window) {
    const user = JSON.parse(String(userInfoStore.get()));
    return user;
  }
  return null;
}
